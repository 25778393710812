.carousel-item{
  //width: 100%;
  max-width: 100%;
  max-height: 100%;
  flex-shrink: 0;
}

.carousel-image{
  //width: 100%;
  top: 0;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  user-select: none;
}

.event-card__nav {
  position: absolute;
  top: 0;
  cursor: pointer;
  z-index: 20;
  border-radius: 50%;
  height: 100%;
}

.event-card__nav-left {
  left: 0;
  color: rgba(20, 20, 020, 1);
  z-index:10001;
  height: 100%;
  width: 10%;
}

.event-card__nav-right {
  color: rgba(20, 20, 020, 1);
  right: 0;
  z-index:10001;
    height: 100%;
  width: 10%;
}

.chevron_left {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  color: black;
  z-index: 10002;
}

.chevron_right {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  color: black;
  z-index: 10002;
}
