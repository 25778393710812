.logo{
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  vertical-align: center;

  img{
    width: 80vw;
    //height: 20vh;
  }

  @media (max-width: 768px) {
    margin-top: 25vh;
    margin-bottom: 25vh;

    img{
      width: 98vw;
      //height: 20vh;
    }
  }
}