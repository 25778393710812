
.header__box{
    display: inline-block;
    position: absolute;
    justify-content: center;
    align-items: flex-start;
    font-size: 3vw;
    font-weight: 800;
}



