body {
  /*Website colour scheme*/
  --main-background: rgb(10,17,61);
  --main-text: #ffffff;
  --main-text-light: #000000;
  --main-accents: rgb(118, 229, 246);
  --main-accents-transparent: rgba(118, 229, 246,0.8);
  --main-accents-light: rgb(10, 168, 179);
  /*background: var(--main-background);*/
  background-image: linear-gradient(rgba(9,16,60,1) 0%, rgba(52,93,181,1) 100%);
  background-position: center;
  /*background-repeat: repeat;*/
  /*background-image: url("assets/BACKGR.png");*/
  /*overflow: hidden;*/
  /*height: 100%;*/
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}@media (max-width: 768px) {
  body {
    background-size: auto 100%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
