
.about_us{

  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  margin: 5vh auto;
  color: var(--main-text);
  text-align: center;
  width: 100vw;
  height: auto;
  p{
    display: inline-flex;
    justify-self: center;
    align-self: center;
    width: 90vw;
  }


}
