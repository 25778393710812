.navbar{
  z-index: 99999;
  height: 10vh;
  position: fixed;
  width: 100vw;
  //border-bottom: 2px solid white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  vertical-align: center;


  .Logo{
    float: left;
    margin-left: 2vw;
    font-size: 2rem;
    font-weight: 700;


    text-decoration: none;
    align-self: center;
    color: var(--main-accents);
    padding: 12px 4px 4px;
    a .selected{
      //background: var(--main-accents-transparent);
      //border-radius: 0 0 20px 20px;
      color: var(--main-accents);
    }
    a{
      color: var(--main-text);
      margin-right: 4vw;
      padding: 12px 4px 4px;
      transition: color 0.5s ease-in-out;
      a:hover{
        //background: var(--main-accents-transparent);
        //border-radius: 0 0 20px 20px;
        transition: 0.25s color ease-in-out;
        color: var(--main-accents);
      }
    }
    a:active{
      text-decoration: none;
    }

  }

  .navbar-nav{
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .nav-item{
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2vw;
  }

  .icon-button{
    width: 8vw;
    height: 8vw;
    font-size: 2rem;
    color: var(--main-text);
    border-radius: 30%;
    padding: 5px;
    margin: 2px;
    margin-right: 4vw;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.25s ease-in-out;
    z-index: 1000;
    @media (min-width: 768px) {
      width: 2vw;
        height: 2vw;
      margin-right: 2vw;
    }

  }
  .icon-button:hover{
    filter: brightness(1.2);
    @media (min-width: 768px) {
      color: var(--main-accents);
    }
  }

  img{
    width: 80vw;
    height: auto;
    margin-bottom: 5vh;

  }




  .icon-open{
    color: var(--main-text);
    transition: all 1s ease-in-out;
    //background-color: rgba(3, 161, 172,0.8);
  }

  .middle-logo{
    height:25vh;
    overflow: hidden;
    align-self: center;
    img {
      width: 20vw;
      height: auto;
      justify-content: flex-end;
    }
  }

  .dropdown{
    position: absolute;
    width: 100vw;
    height: 100vh;
    //border-radius: 5px;
    overflow: hidden;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.8);
    backdrop-filter: blur(8px);
    transition: ease-in-out 1s;

  }

  .dropdown-invisible{
    //display: none;
    top:-100vh;
    left: 0;
    height: 100vh;
    width: 100vw;
    position: absolute;
    transition: ease-in-out 1s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }


  .menu-item{
    height: auto;
    display: inline-block;
    float: right;
    padding: 0 1rem;
    transition: background-color 300ms;
    &.selected{
      color: var(--main-accents);
    }

  }

  .icon-left{
    //margin-left: 1rem;
    margin-right: 5vw;
  }

  ul{
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a{
    text-decoration: none;
    color: var(--main-text);
    &first-child{
      margin: auto;
    }
  }
}
