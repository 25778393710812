.home_page{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;

/*    add space between children*/
/*    & > *{*/
/*        margin-top: 2vh;*/
/*    }*/
}