.about-page{
  //display: grid;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  min-height: 100vh;
  margin-top: 5vh;
  height: auto;
  //grid-template-columns: 49vw 49vw;
  //grid-column-gap: 2vw;


  .logo{
    grid-column: 1;
    margin: 0 2vw;
    vertical-align: center;
    justify-content: center;
    justify-self: center;
    align-self: center;
    img{
        width: 100%;
        height: auto;
    }
  }

  .about-text{
    grid-column: 2;
    margin: 0 auto;
    display: block;
    width: 70vw;
    text-align: left;
    font-size: 1rem;
    line-height: 2rem;
    p{
      margin-bottom: 4rem;
    }
  }

  .CollapsibleGroup{
    border: dotted white 3px;
    border-radius: 10px;
    padding: 2rem;
    //background: red;
  }

  .about-links{
    display: flex;
    flex-direction: row;
    justify-content: center;
    a {
      margin-left: 30px;
      margin-right: 30px;
      color: white;
      text-decoration: none;
      font-weight: bold;
      font-size: 3rem;
      &:hover {
        color: var(--main-accents-light);
      }
    }
  }

}

