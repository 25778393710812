.eventScrollDiv{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;

}

//.eventCard{
//
//  .eventText{
//    display: flex;
//    flex-direction: column;
//    background: var(--main-background);
//    height: 100%;
//    width: 100%;
//    //margin-left: 10px;
//    position: relative;
//    border-radius: 0.5rem;
//    //top: -20%;
//    grid-row: 1/-1;
//    color: var(--main-text);
//    margin: 0;
//    h2{
//      margin: 5px;
//      align-self: flex-start;
//      font-size: 2.5rem;
//      font-weight: 600;
//      color: var(--main-text);
//      //text-align: center;
//    }
//    p{
//        margin: 5px;
//      font-size: 1.5rem;
//      font-weight: 600;
//      color: var(--main-text);
//      //text-align: center;
//    }
//  }
//
//  display: grid;
//  grid-template-rows: 89% 10%;
//  grid-row-gap: 1%;
//  align-items: center;
//  width: 300px;
//  height: 330px;
//  background: var(--main-background);
//
//  //backdrop-filter:
//  border-radius: 0.5rem;
//  margin: 4rem 2rem 2rem;
//  //border: solid 2px var(--main-accents);
//  padding: 0.3rem;
//
//  .eventCardImg{
//    grid-row: 1;
//    overflow: hidden;
//    display: flex;
//    width:100%;
//    height:100%;
//    max-height: 100%;
//    align-content: center;
//    justify-content: center;
//    align-items: center;
//    vertical-align: center;
//    border-radius: 0.5rem;
//    background: rgba(0, 0, 0,0.5);
//    img{
//      align-self:center;
//      max-height: 100%;
//      max-width: 100%;
//      //height: 100%;
//      //object-fit: cover;
//      width: 100%;
//    }
//
//    button{
//        background-color: transparent;
//        border: 1px solid black;
//        border-radius: 50%;
//        width: 2rem;
//        height: 2rem;
//        outline: none;
//        cursor: pointer;
//        transition: all 0.3s ease-in-out;
//        &:hover{
//
//        }
//    }
//  }
//  .eventCardContent{
//    grid-row: 2;
//    width: 100%;
//    display: flex;
//    flex-direction: row;
//    justify-content: space-between;
//    align-content: center;
//    align-items: center;
//    vertical-align: middle;
//
//    height: 100%;
//    //border-top: solid 1px black;
//    //margin-top: 10px;
//    padding-top: 0;
//    .arrowButton{
//      margin-left: 0.5rem;
//      align-self: center;
//      vertical-align: center;
//        background-color: transparent;
//      border-radius: 50%;
//      //width: 2rem;
//      border: none;
//      height: 100%;
//      font-size: 1.5rem;
//      color: var(--main-text);
//      outline: none;
//      cursor: pointer;
//      transition: all 0.3s ease-in-out;
//        &:hover{
//            scale: 0.95;
//        }
//    }
//    .eventCardInfo{
//      font-size: 1.8rem;
//    }
//    //.leftButton{
//    //  position: absolute;
//    //  top: 50%;
//    //  left: 0%;
//    //  transform: translate(0, -50%);
//    //}
//    //.rightButton{
//    //  position: absolute;
//    //  top: 50%;
//    //  right: 0%;
//    //  transform: translate(0, -50%);
//    //}
//
//    .eventCardTitle{
//        font-size: 1.5rem;
//        font-weight: 600;
//        color: var(--whitee-color);
//    }
//    .signUpButton{
//      margin-right: 0.5rem;
//      justify-self: flex-end;
//
//      a{
//        text-decoration: none;
//        color: var(--main-text-light);
//        background-color: white;
//        padding: 2px 4px;
//        border: 1px solid black;
//        border-radius: 10px;
//        font-size: 1rem;
//        font-weight: 600;
//        transition: all 0.3s ease-in-out;
//        &:hover{
//          background-color: var(--main-accents);
//          color: var(--main-text-light);
//        }
//      }
//    }
//    //.eventCardInfo{
//    //  margin-left: 1rem;
//    //  height: 100%;
//    //  width: auto;
//    //  float: left;
//    //
//    //  &:hover{
//    //    scale: 0.95;
//    //  }
//    //}
//  }
//}
//
//.eventScrollDiv{
//  display: flex;
//  flex-wrap: wrap;
//  flex-direction: row;
//  justify-content: center;
//}
//
//.eventCard {
//  /* Other styles... */
//  transition: all 0.3s ease; /* Smooth transition for expanding and collapsing */
//}
//
//// expand and center the card on the screen
//.eventCard.expanded {
//  position: fixed;
//  transform: translate(0, -70%);
//  //top: 2.5vh;
//  //left: 1.25vw;
//  margin: 0;
//  width: 95vw;
//  height: 95vh;
//}



