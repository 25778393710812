.guest-lectures{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 100vw;
  //padding: 0 2vw;
  height: auto;
  margin-top: 10vh;

  .headerBox{
    //background: var(--main-accents-transparent);
    width: 100vw;
    border-bottom: white solid 1px;
    height: 10vh;
    vertical-align: center;
    //text-align: center;
    text-align: left;
    padding-left: 2vw;
    //padding: 0 2vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  align-content: center;
  vertical-align: center;
}