.event-card {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: row;
    margin: 20px;
    width: 300px;
    height: 300px;
    overflow: hidden;
    cursor: pointer;
    border-radius: 10px;

    transition: transform 0.3s ease-in-out;
}

.event-card.expanded{
    transform: scale(1.1);
}

.event-card__image-container {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 100%;
}

.event-card__image {
    height: auto;
    max-height: 100%;
    width: 100%;
    object-fit: cover;
    top: 0;
}

.event-card__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

}

.event-card__overlay.expanded {
    opacity: 1;
}

.event-card__overlay-content {
    text-align: center;
}

.event-card__details {
    padding: 1rem;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.event-card__details.expanded {
    opacity: 1;
}

.event-card__cta {

    position: absolute; /* Position it relative to the event-card */
    bottom: -10px; /* Place it towards the bottom */
    /*left: 50%;*/
    /*transform: translateX(-50%); !* Center horizontally *!*/
    width: 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 30px;
    background-color: rgba(255, 255, 255, 0.7); /* Dark background */
    z-index: 10; /* Ensure it's on top of other content */
    color: rgba(0,0,0,0.8); /* Make sure the text color is visible against the background */
    animation: pulse 2s infinite;
    text-align: center;
}

.event-card__chevron {
        transition: transform 0.2s ease-in-out;
}


@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.event-card:hover .event-card__image {
    transform: scale(1.05); /* Slightly enlarges the image */
    transition: transform 0.3s ease-in-out;
}

.event-card:hover .event-card__overlay {
    background-color: rgba(0, 0, 0, 0.8); /* Darkens the overlay a bit */
}

