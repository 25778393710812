.image-carousel{
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  touch-action: pan-y;
  position: relative;
  border-radius: 10px;
  z-index: 9999;
  background: rgba(0, 0, 0,0.5);

}

.image-carousel-list{
  min-width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  vertical-align: center;
    align-items: center;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;

  transition: transform 0.3s ease-out;

  .is-swiping{
    transition: none;
  }
}

.image-carousel-indicator{
  display: flex;
  position: absolute;
  justify-content: center;
  list-style: none;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  //margin: 15px 0 0 0;
  padding: 0;
  z-index: 10000;
}

.image-carousel-indicator-item{
  width: 12px;
  height: 12px;
  margin: 0 4px;
  border-radius: 50%;
  background-color: #ccc;
  cursor: pointer;

  &.active{
    background-color: #777;
  }
}



