.EventsPage{
  .placeholderText{
    margin: 5vw 0 5vw 2vw;
  }
  .headerBox{
    //background: var(--main-accents-transparent);
    //width: 100vw;
    border-bottom: white solid 1px;
    height: 10vh;
    vertical-align: center;
    //text-align: center;
    text-align: left;
    //padding-left: 2vw;
    padding: 0 2vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .descriptionText{
    //background: rgba(3, 161, 172,0.8);
    width: 100vw;
    height: 1vh;
    vertical-align: center;
    //text-align: center;
    text-align: left;
    padding-left: 2vw;
  }
  align-content: center;
  vertical-align: center;
}