.links{
  height: 10vh;
  display: flex;
  margin: 5vh auto;
  text-align: center;
  overflow-y: hidden;
  vertical-align: center;
  justify-content: center;

  font-size: 15vw;

  //border-top: 1px solid lightgrey;
  //border-bottom: 1px solid lightgrey;
  //background: #242424;


  color: var(--main-text);
  a, a:hover, a:focus, a:active {
    text-decoration: none;
    color: inherit;
    margin: auto 3vw;
    vertical-align: center;
  }
  a .selected{
    color: var(--main-accents);
  }

  @media (min-width : 768px) {
    font-size: 5vw;
    height: 15vh;
    vertical-align: center;
    a:hover {
      color: var(--main-accents-light);
    }
  }
}