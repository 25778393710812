.lectureCard{
  position: relative;
  display: grid;
  grid-template-rows: 90% 9%;
  grid-row-gap: 1%;
  //grid-template-columns: 94% 5%;
  grid-column-gap: 1%;
  align-items: center;
  align-self: center;
justify-self: center;
  width: 300px;
  height: 330px;
  background: var(--main-background);
  border-radius: 0.5rem;
  margin: 4rem 2rem 2rem;
  //border: solid 2px var(--main-accents);
  padding: 0.3rem;


  //&.infoSelected{
  //  width: 60vw;
  //}

  .lectureCardImg{
    grid-column: 1;
    overflow: hidden;
    display: flex;
    width:100%;
    //height:100%;
    max-height: 100%;
    max-width: 100%;
    align-content: center;
    justify-content: center;
    align-items: center;
    vertical-align: center;
    border-radius: 0.5rem;
    background: rgba(0, 0, 0,0.5);
    img{
      align-self:center;
      max-height: 100%;
      max-width: 100%;
      width: 100%;
      //height: 100%;
      //object-fit: cover;
      //height: 100%;

    }

    button{
      background-color: transparent;
      border: 1px solid black;
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      outline: none;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover{

      }
    }
  }

  .lectureCardInformation{
    background: var(--main-accents-transparent);
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    border-radius: 0.2rem;
    height: 100%;
    max-height: 0;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    color: var(--main-text-light);
    transition: all 0.3s ease-in-out;
    //max-height: 0;

    &.info-visible{
      backdrop-filter: blur(5px);

      visibility: visible;
      max-height:100%;
      height: 100%;
      transition: all 0.3s ease-in-out;
    }
    //padding: 10px;
    p{
      margin: 10px 20px;

      &.lectureTitle{
        font-size: 23px;
        font-weight: 600;
        text-align: center;
        margin: 10px 5px 0;
      }

      &.lecturePresentor{
        font-size: 20px;
        text-align: left;
        font-weight: 400;
        align-self: flex-start;
        margin: 0 8px 10px;
      }
    }

    .lectureDownload{
      margin: auto;
      margin-bottom: 5px;
    }

    a{
      text-decoration: none;
      color: var(--main-text);
      background-color: var(--main-background);
      padding: 2px 4px;
      //margin-top: 10px;
      border: 1px solid black;
      border-radius: 10px;
      font-size: 1rem;
      font-weight: 600;
      transition: all 0.3s ease-in-out;
      //margin-top: auto;
      margin-bottom: 20px;
      &:hover{
        background-color: var(--main-accents);
        color: var(main-text-light);
      }
    }
  }

  .lectureCardContent{
    grid-row: 2;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    vertical-align: middle;

    height: 100%;
    //align-items: center;
    padding-top: 0;


    .arrowButton{
      align-self: center;
      vertical-align: center;
      background-color: transparent;
      border-radius: 50%;
      //width: 2rem;
      border: none;
      height: 100%;
      font-size: 1.5rem;
      color: black;
      outline: none;
      cursor: pointer;
      z-index: 100;
      transition: all 0.3s ease-in-out;
      &:hover{
        scale: 0.95;
      }

      &.selected{
        color: white;
      }
    }
    .lectureCardInfo{
      font-size: 1.8rem;
    }

    //.leftButton{
    //  position: absolute;
    //  top: 50%;
    //  left: 0%;
    //  transform: translate(0, -50%);
    //}
    //.rightButton{
    //  position: absolute;
    //  top: 50%;
    //  right: 0%;
    //  transform: translate(0, -50%);
    //}


    .signUpButton{
      margin-right: 1rem;
      justify-self: flex-end;
      a{
        text-decoration: none;
        color: black;
        background-color: white;
        padding: 2px 4px;
        border: 1px solid black;
        border-radius: 10px;
        font-size: 1rem;
        font-weight: 600;
        transition: all 0.3s ease-in-out;
        &:hover{
          background-color: rgb(3, 161, 172);
          color: white;
        }
      }
    }
  }
}

.lectureTitle{
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--whitee-color);
}